import React, { useContext, useEffect, useState } from 'react'
import { Routes, Route, BrowserRouter, Navigate, useLocation } from 'react-router-dom';
import Home from '../pages/Home.jsx';
import About from '../pages/About.jsx';
import ScemeDetail from '../pages/ScemeDetail.jsx';
import Sceme from '../pages/Sceme.jsx';
import ProductList from '../pages/Product/ProductList.jsx';
import ProductDetails from '../pages/Product/ProductDetails.jsx';
import ContactUs from '../pages/ContactUs.jsx';
import MyAccount from '../pages/MyAccount.jsx';
import Mainroutes from './Mainroutes.jsx';
import MyDashboard from '../dashboard/MyDashboard.jsx';
import Profile from '../dashboard/Profile.jsx';
import Kyc from '../dashboard/Kyc.jsx';
import DigitalGold from '../dashboard/DigitalGoldSchemes.jsx';
import Notifycation from '../dashboard/Notifycation.jsx';
import Myorder from '../dashboard/Myorder.jsx';
import MyBankAccount from '../dashboard/MyBankAccount.jsx';
import { Faqpage } from '../pages/Faqpage.jsx';
import { Logindetail } from '../context/Logindetail.js';
import { PagesApi } from '../api/Pageapi.js';
import ChitSchemes from '../dashboard/ChitSchemes.jsx';
import Beneficiaries from '../dashboard/Beneficiaries.jsx';
import { Footerslug } from '../pages/Footerslug.jsx';
import { getSettingApi } from '../api/Controllapi.js';
import GoldPlantsSchems from '../dashboard/GoldPlantsSchems.jsx';
import { Footerpages } from '../context/Footerpages.js'
import Commission from '../dashboard/Commission.jsx';


const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = 'manual';
  }, [pathname]);
}


const Approutes = () => {

  const { token, detail_info } = useContext(Logindetail);
  const { setfooterpage } = useContext(Footerpages)
  const [setting_data, setsetting_data] = useState([])
  const [footer_pages, setfooter_pages] = useState([])
  const [openSchemes, setopenSchemes] = useState(false)
  const [Isloading, setIsloading] = useState(true)


  const setting_fun = () => {
    getSettingApi()
      .then((res) => {
        setsetting_data(res?.data)
        setIsloading(false)
      })
      .catch((err) => {
        console.log(err);
        setIsloading(true)
      })
  }

  const settingpages = () => {
    PagesApi()
      .then((res) => {
        setfooter_pages(res?.data)
        setIsloading(false)
        setfooterpage(res?.data)
      })
      .catch((err) => {
        console.log(err)
        setIsloading(true)
      })
  }


  useEffect(() => {
    setting_fun()
    settingpages()
  }, [])

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>

        {
          token && detail_info ?
            <Route element={<Mainroutes openSchemes={openSchemes} setting_data={setting_data} footerpages={footer_pages} />}>
              <Route path="/user/dashboard" element={<MyDashboard settings={setting_data} />} />
              {/* <Route path="/user/transaction" element={<Transaction />} /> */}
              <Route path="/user/chit-jewels" element={<ChitSchemes settings={setting_data} />} />
              <Route path="/user/digital-gold" element={<DigitalGold settings={setting_data} />} />
              <Route path="/user/gold-plant" element={<GoldPlantsSchems settings={setting_data} />} />
              <Route path="/user/beneficiaries" element={<Beneficiaries settings={setting_data} />} />
              <Route path="/user/kyc" element={<Kyc settings={setting_data} />} />
              <Route path="/user/myprofile" element={<Profile settings={setting_data} />} />
              <Route path="/user/Notifycation" element={<Notifycation settings={setting_data} />} />
              <Route path="/user/mybank-account" element={<MyBankAccount settings={setting_data} />} />
              <Route path="/user/myorders" element={<Myorder settings={setting_data} />} />
              <Route path='/user/commission' element={<Commission settings={setting_data} />} />
              <Route path="/user/account-setup" element={<MyAccount />} />
              <Route path='/user/pages/:slug' element={<Footerslug />} />
            </Route> : <>
              <Route path="/user/*" element={<Navigate to='/' />} />
              <Route path="/user" element={<Navigate to='/' />} />
            </>
        }

        <Route element={<Mainroutes openSchemes={openSchemes} setting_data={setting_data} footerpages={footer_pages} />}>
          <Route path="/" element={<Home setopenScheme={setopenSchemes} setting_datas={setting_data} />} />
          <Route path="/about" element={<About setting_datas={setting_data} />} />
          <Route path="/scheme-detail/:slug" element={<ScemeDetail setting_datas={setting_data} />} />
          <Route path="/scheme" element={<Sceme setopenScheme={setopenSchemes} setting_datas={setting_data} />} />
          <Route path="/gold-silver" element={<ProductList />} />
          <Route path="/detail/:id" element={<ProductDetails />} />
          <Route path="/contact" element={<ContactUs setting_data={setting_data} />} />
          <Route path='/Faq' element={<Faqpage setting_datas={setting_data} />} />
          <Route path='/pages/:slug' element={<Footerslug />} />
          <Route path="*" element={<div className='text-center'>
            <img src='/assets/images/404img.png' />
          </div>} />  {/* Fallback for undefined routes */}
        </Route>

      </Routes>
    </BrowserRouter>
  )

}


export default Approutes;
