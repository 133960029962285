"use client"

import React, { useEffect, useState } from "react"
import {getlive_fun } from "../api/Pageapi"

export const Liveprice = ({settings_datas}) => {


    return (
           <div className="d-flex   justify-content-center ">
        
                    <div className="goldsiver-box bg-white p-4 mt-5">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            {/* <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#goldtype" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Digital gold</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#silvertype" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Digital silver </button>
                            </li> */}
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="" data-bs-toggle="pill" data-bs-target="" type="button" role="" aria-controls="pills-home" aria-selected="true">Digital gold / Silver</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                        <>
                                <div className="livebuy d-flex align-items-center gap-2">
                                    <img src='\assets\iocns\blink_img.gif' className='img-fluid' />
                                    <h5 className='m-0'>Live Buy Price (Gold) <span>99.09%</span></h5>
                                </div>
                                <div className="goldrate-gm d-flex  align-items-center justify-content-between my-3">
                                    <h3 className='m-0'>{settings_datas ? settings_datas[0]?.digital_gold : 'Loading...'}/gm</h3>
                                    <h6 className='m-0'>+3% GST applicable</h6>
                                </div>
                            </>
                            <>
                                <div className="livebuy d-flex align-items-center gap-2">
                                    <img src='\assets\iocns\blink_img.gif' className='img-fluid' />
                                    <h5 className='m-0'>Live Buy Price (Silver) <span>24k.99.0</span></h5>
                                </div>
                                <div className="goldrate-gm d-flex  align-items-center justify-content-between my-3">
                                    <h3 className='m-0'>{settings_datas ? settings_datas[0]?.digital_silver : 'Loading...'}/gm</h3>
                                    <h6 className='m-0'>+3% GST applicable</h6>
                                </div>
                            </>
                            {/* <div className="tab-pane fade show active" id="goldtype" role="tabpanel">
                                <div className="livebuy d-flex align-items-center gap-2">
                                    <img src='\assets\iocns\live.png' className='img-fluid' />
                                    <h5 className='m-0'>Live Buy Price <span>99.09%</span></h5>
                                </div>
                                <div className="goldrate-gm d-flex  align-items-center justify-content-between my-3">
                                    <h3 className='m-0'>{settings_datas ? settings_datas[0]?.digital_gold : 'Loading...'}/gm</h3>
                                    <h6 className='m-0'>+3% GST applicable</h6>
                                </div>
                            </div>
                            <div className="tab-pane fade show " id="silvertype" role="tabpanel">
                                <div className="livebuy d-flex align-items-center gap-2">
                                    <img src='\assets\iocns\live.png' className='img-fluid' />
                                    <h5 className='m-0'>Live Buy Price <span>24k.99.0</span></h5>
                                </div>
                                <div className="goldrate-gm d-flex  align-items-center justify-content-between my-3">
                                    <h3 className='m-0'>{settings_datas ? settings_datas[0]?.digital_silver : 'Loading...'}/gm</h3>
                                    <h6 className='m-0'>+3% GST applicable</h6>
                                </div>
                            </div> */}
                        </div>

                    </div>

    
           </div>

    )

}

