import axios from 'axios'
import React, { useEffect, useState, useCallback, useContext, useRef } from 'react'
import HeadingBanner from '../Components/HeadingBanner.jsx'
import FAQ from '../Components/FAQ.jsx'
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Grid } from "swiper/modules";
import { Shimmer } from 'react-shimmer';
import { trestiAPi, getPlanDetail, PLANS, getTermsCondition } from '../api/Controllapi.js';
import { whychoose, Gettrust_fun, Getabout_fun } from '../api/Pageapi.js';
import AOS from 'aos'
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// Ray S
import { Logindetail } from '../context/Logindetail.js';
import { SchemeDetails } from '../context/SchemeDetails.js';
import useRazorpay from "react-razorpay";
import { Footerpages } from '../context/Footerpages.js';
import toast, { useToaster } from 'react-hot-toast';
// Ray E


import { Enroll_fun, fetchBeneficiaries, getSavingPlan_fun, postSchemaSave } from '../api/Pageapi';
import { Digitalscheme_fun, Getdigital_trans, GetPlanbenefit, Savedigitalplan } from '../api/RouteScheme2';
import { GoldPlant_fun, SaveGoldplan } from '../api/RouteScheme3';
import { schemeplans } from '../api/Controllapi';
import { Arrow90degLeft, ArrowLeft, ArrowUp, ArrowUpShort, ChevronDown, X } from 'react-bootstrap-icons';


export default function ScemeDetail({ setopenSchemes, setting_datas }) {

    const scheme_slug = useParams().slug;
    const router = useNavigate();


    const [testimoanl_data, settestimoanl_data] = useState([])
    const [Isloading, setIsloading] = useState(true)
    const [ChooseData, setChooseData] = useState()
    const [Trustdata, setTrustData] = useState()
    const [AboutData, setAboutData] = useState({})

    const [PlanDetails, setPlanDetails] = useState({})
    const [TermsCondition, setTermsCondition] = useState([])


    // Razor Pay Section Start


    // const RAZAR_KEY = "rzp_test_K7CipNQYyyMPiS";

    const RAZAR_KEY = "rzp_live_FZ6iVw5tcRGpW5";

    const { token, detail_info, setdetail_info } = useContext(Logindetail)
    const { setschemelist } = useContext(SchemeDetails)
    const [scheme_list, setscheme_list] = useState({});
    const [Razorpay, isLoaded] = useRazorpay();
    const [showRazorpayModal, setShowRazorpayModal] = useState(false);
    const [shemData, setSchemaData] = useState()
    const [price, setprice] = useState();
    const [isLoading, setisLoading] = useState(true);
    const [benfic, setbenfic] = useState([]);
    const [DigitaScheme, setDegitalScheme] = useState({})
    const [Benifitid, setBenifitid] = useState()
    const [Goldplant_data, setGoldplant_data] = useState()
    const [checked, setchecked] = useState(false)
    const { footerpage } = useContext(Footerpages)
    const fromsubmit = useRef()
    const [ChargeAmt, setChargeAmt] = useState()
    const [benificid, setbenificid] = useState('')
    const [process_type, setprocess_type] = useState()
    const [clientamount, setclientamount] = useState()
    const [Transactionid, setTranscationid] = useState()
    const [transfile, settransfile] = useState()
    const [file1, setfile1] = useState()
    const [spinner, setspinner] = useState()
    const [Enroll_data, setEnroll_data] = useState()




    const logout = () => {
        localStorage.removeItem("green_login")
        localStorage.removeItem("phone")
        localStorage.removeItem("green_token")
        localStorage.removeItem("kyc")
        router('/');
        setdetail_info();
    }

    const handlefectch = () => {
        fetchBeneficiaries()
            .then(res => {
                setbenfic(res?.data)
                setisLoading(false);
                if (res?.data?.status == 403) {
                    logout();
                }
            }).catch(err => {
                console.log('err', err.message);
                setisLoading(false)
            });
    }

    useEffect(() => {
        if (detail_info && token) {
            handlefectch()
        }
    }, [detail_info?.id])


    // 1 plan
    const handlePayment = useCallback(async () => {

        const options = {
            key: RAZAR_KEY,
            amount: (clientamount) * 100,
            currency: "INR",
            name: 'Greenheap',
            description: 'Greenheap',
            image: "/assets/iocns/__Fav Icon.png",
            handler: (res) => {
                const body = {
                    "amount": clientamount,
                    "saving_plan_id": shemData?.id,
                    "transaction_id": res?.razorpay_payment_id,
                    'benificiar': benificid,
                    "payment_method": 'razor_pay',
                }
                postSchemaSave(body, token).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    router('/user/dashboard')
                    toast.success(`Payment successful from ${shemData?.name}`)
                    setShowRazorpayModal(false);
                }).catch(err => {
                    console.log('err', err.message)
                })
            },

            theme: {
                color: '#650020',
            },
        };

        const rzpay = new Razorpay(options);

        rzpay.open();

        // Reset the showRazorpayModal state after payment completion

        rzpay.on('payment.failed', function (response) {
            setShowRazorpayModal(false);
            console.error(response.error.description);
            toast.error(response.error.description)
        });

    }, [price, shemData]);

    // 2 plan

    const handlePaymentDigital = useCallback(async () => {

        const options = {
            key: RAZAR_KEY,
            amount: (clientamount) * 100,
            currency: "INR",
            name: 'Greenheap',
            description: 'Greenheap',
            image: "/assets/iocns/__Fav Icon.png",
            handler: (res) => {
                const body = {
                    "digital_plan_id": shemData?.id,
                    "amount": clientamount,
                    "transaction_id": res?.razorpay_payment_id,
                    // 'benificiar': benificid,
                    "payment_method": 'razor_pay',
                }
                Savedigitalplan(body).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    router('/user/dashboard')
                    toast.success(`Payment successful from ${shemData?.name}`)
                    setShowRazorpayModal(false);
                }).catch(err => {
                    console.log('err', err.message)
                })


            },
            theme: {
                color: '#650020',
            },
        };

        const rzpay = new Razorpay(options);

        rzpay.open();

        // Reset the showRazorpayModal state after payment completion
        rzpay.on('payment.failed', function (response) {
            setShowRazorpayModal(false);
            console.error(response.error.description);
            toast.error(response.error.description)
        });

    }, [price, shemData]);

    // 3 plan
    const handlePaymentgoldplant = useCallback(async () => {
        const options = {
            key: RAZAR_KEY,
            amount: (ChargeAmt) * 100,
            currency: "INR",
            name: 'Greenheap',
            description: 'Greenheap',
            image: "/assets/iocns/__Fav Icon.png",
            handler: (res) => {
                const body = {
                    "gold_plant_id": shemData?.id,
                    "amount": ChargeAmt,
                    "transaction_id": res?.razorpay_payment_id,
                    'benificiar': Benifitid,
                    "payment_method": 'razor_pay',
                }
                SaveGoldplan(body).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    router('/user/dashboard')
                    toast.success(`Payment successful from ${shemData?.name}`)
                    setShowRazorpayModal(false);
                    setbenificid()
                }).catch(err => {
                    console.log('err', err.message)
                })
            },
            theme: {
                color: '#650020',
            },
        };

        const rzpay = new Razorpay(options);

        rzpay.open();

        // Reset the showRazorpayModal state after payment completion
        rzpay.on('payment.failed', function (response) {
            setShowRazorpayModal(false);
            console.error(response.error.description);
            toast.error(response.error.description)
        });

    }, [ChargeAmt, shemData]);



    const handlepayModal = () => {

        if (shemData?.plan_title === "saving_plan") {
            handlePayment()
        }
        if (shemData?.plan_title === "digital_plan") {
            handlePaymentDigital()
        }
        if (shemData?.plan_title === "gold_plant") {
            handlePaymentgoldplant();
        }
    }

    useEffect(() => {
        AOS.init({
            duration: 1500
        });
    }, []);

    const getscheme_plans = () => {
        schemeplans(detail_info?.id ? detail_info?.id : '')
            .then((res) => {
                setscheme_list(res?.data[0])
                setDegitalScheme(res?.data[1])
                setGoldplant_data(res?.data[2])
                setisLoading(false)
                setschemelist(res?.data)
            })
            .catch((err) => {
                console.log(err)
                setisLoading(false)
            })
    }


    // useEffect(() => {
    //   getscheme_plans()
    // }, [detail_info?.id])


    const paymentfun = (e) => {
        e.preventDefault()

        if (process_type == '1') {
            setShowRazorpayModal(true);
            handlepayModal();
            document.getElementById('payment-pup').click()
            document.getElementById('Rupees').value = null
        }
        else if (process_type == '2') {
            document.getElementById('payment-pup').click()
            document.getElementById('offlinepop-pup').click()
            document.getElementById('transaction-id').value = null
            setfile1()
            settransfile()
            setTranscationid()
        }
        else if (process_type == '3') {
            document.getElementById('payment-pup').click()
            document.getElementById('bankpop-pup').click()
            document.getElementById('transaction-id2').value = null
            setTranscationid()
        }
        else {
            toast.error('please select payment method')
        }

    }

    const upimethod = (e) => {
        e.preventDefault()

        if (!transfile) {
            toast.error('please Upload the transaction slip');
            return false;
        }

        if (shemData?.plan_title === 'saving_plan') {

            if (Transactionid || transfile) {

                setspinner(true)

                const formdata = new FormData()
                formdata.append('amount', clientamount)
                formdata.append('saving_plan_id', shemData?.id)
                formdata.append('transaction_id', Transactionid ? Transactionid : '1243')
                formdata.append('benificiar', benificid)
                formdata.append('payment_method', 'upi')
                formdata.append('uploads', transfile)

                postSchemaSave(formdata).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    setspinner(false)
                    toast.success(`Payment successful from ${shemData?.name}`)
                    document.getElementById('offlinepop-pup').click()
                    router('/user/dashboard')
                    setShowRazorpayModal(false);
                }).catch(err => {
                    console.log('err', err.message)
                })


            }
            else {
                toast.error('please Upload the transaction slip or enter the transaction ID')
            }
        }

        if (shemData?.plan_title === "digital_plan") {

            if (Transactionid || transfile) {

                setspinner(true)
                const formdata = new FormData()
                formdata.append('amount', clientamount)
                formdata.append('digital_plan_id', shemData?.id)
                formdata.append('transaction_id', Transactionid ? Transactionid : '1243')
                formdata.append('benificiar', benificid)
                formdata.append('payment_method', 'upi')
                formdata.append('uploads', transfile)

                Savedigitalplan(formdata).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    toast.success(`Payment successful from ${shemData?.name}`)
                    document.getElementById('offlinepop-pup').click()
                    router('/user/dashboard')
                    setShowRazorpayModal(false);
                    setspinner(false)
                }).catch(err => {
                    console.log('err', err.message)
                })


            }
            else {
                toast.error('please Upload the transaction slip or enter the transaction ID')
            }
        }

        if (shemData?.plan_title === "gold_plant") {

            if (Transactionid || transfile) {

                setspinner(true)

                const formdata = new FormData()
                formdata.append('amount', clientamount)
                formdata.append('gold_plant_id', shemData?.id)
                formdata.append('transaction_id', Transactionid ? Transactionid : '1243')
                formdata.append('benificiar', benificid)
                formdata.append('payment_method', 'upi')
                formdata.append('uploads', transfile)

                SaveGoldplan(formdata).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    toast.success(`Payment successful from ${shemData?.name}`)
                    document.getElementById('offlinepop-pup').click()
                    router('/user/dashboard')
                    setShowRazorpayModal(false);
                    setspinner(false)
                }).catch(err => {
                    console.log('err', err.message)
                })


            }
            else {
                toast.error('please Upload the transaction slip or enter the transaction ID')
            }
        }

    }


    const Bankmethod = (e) => {
        e.preventDefault()

        if (!transfile) {
            toast.error('please Upload the transaction slip');
            return false;
        }

        if (shemData?.plan_title === 'saving_plan') {

            if (Transactionid || transfile) {

                setspinner(true)

                const formdata = new FormData()
                formdata.append('amount', clientamount)
                formdata.append('saving_plan_id', shemData?.id)
                formdata.append('transaction_id', Transactionid ? Transactionid : '1243')
                formdata.append('benificiar', benificid)
                formdata.append('payment_method', 'bank')
                formdata.append('uploads', transfile)

                postSchemaSave(formdata).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    toast.success(`Payment successful from ${shemData?.name}`)
                    document.getElementById('bankpop-pup').click()
                    router('/user/dashboard')
                    setShowRazorpayModal(false);
                    setspinner(false)
                }).catch(err => {
                    console.log('err', err.message)
                })


            }
            else {
                toast.error('please Upload the transaction slip or enter the transaction ID')
            }
        }
        if (shemData?.plan_title === "digital_plan") {

            if (Transactionid || transfile) {

                setspinner(true)

                const formdata = new FormData()
                formdata.append('amount', clientamount)
                formdata.append('digital_plan_id', shemData?.id)
                formdata.append('transaction_id', Transactionid ? Transactionid : '1243')
                formdata.append('benificiar', benificid)
                formdata.append('payment_method', 'bank')
                formdata.append('uploads', transfile)

                Savedigitalplan(formdata).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    toast.success(`Payment successful from ${shemData?.name}`)
                    document.getElementById('bankpop-pup').click()
                    router('/user/dashboard')
                    setShowRazorpayModal(false);
                    setspinner(false)
                }).catch(err => {
                    console.log('err', err.message)
                })


            }
            else {
                toast.error('please Upload the transaction slip or enter the transaction ID')
            }
        }
        if (shemData?.plan_title === "gold_plant") {

            if (Transactionid || transfile) {

                setspinner(true)
                const formdata = new FormData()
                formdata.append('amount', clientamount)
                formdata.append('gold_plant_id', shemData?.id)
                formdata.append('transaction_id', Transactionid ? Transactionid : '1243')
                formdata.append('benificiar', benificid)
                formdata.append('payment_method', 'bank')
                formdata.append('uploads', transfile)

                SaveGoldplan(formdata).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    toast.success(`Payment successful from ${shemData?.name}`)
                    document.getElementById('bankpop-pup').click()
                    router('/user/dashboard')
                    setShowRazorpayModal(false);
                    setspinner(false)
                }).catch(err => {
                    console.log('err', err.message)
                })


            }
            else {
                toast.error('please Upload the transaction slip or enter the transaction ID')
            }
        }

    }

    const screenshot = (e) => {
        setfile1(e.target.files[0]?.name)
        settransfile(e.target.files[0])
    }


    const getenroll = () => {
        Enroll_fun()
            .then((res) => {
                setEnroll_data(res?.data)
                setisLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setisLoading(false)
            })
    }

    useEffect(() => {
        getenroll()
    }, [])

    // Razor Pay Section End




    const testimonal_fun = async () => {
        trestiAPi()
            .then((res) => {
                setIsloading(true)
                settestimoanl_data(res?.data)
                setIsloading(false)
            })
            .catch((err) => {
                console.log(err)
                setIsloading(true)
            })
    }


    const getWhyChoose = () => {
        whychoose()
            .then((res) => {
                setChooseData(res?.data)
                setIsloading(false)
            })
            .catch((err) => {
                console.log(err)
                setIsloading(true)
            })
    }

    const gettrust = () => {
        Gettrust_fun()
            .then((res) => {
                setTrustData(res?.data)
                setIsloading(false)
            })
            .catch((err) => {
                console.log(err)
                setIsloading(true)
            })
    }

    const getaboutdetail = () => {
        Getabout_fun()
            .then((res) => {
                setAboutData(res?.data)
                setIsloading(false);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getSchemePlanDetails = () => {

        getPlanDetail(scheme_slug)
            .then((res) => {
                setPlanDetails(res?.data)

                setIsloading(false);
            })
            .catch((err) => {
                console.log(err)
            })

    }

    const getTerms = () => {
        var termSlug = scheme_slug == 'saving_plan' ? 'saving-plan' : (scheme_slug == 'digital_plan' ? 'digital-gold-plan' : 'gold-plant');
        getTermsCondition(termSlug)
            .then((res) => {
                setTermsCondition(res?.data)
                setIsloading(false);
            })
            .catch((err) => {
                console.log(err)
            })

    }

    useEffect(() => {

        testimonal_fun()
        getWhyChoose()
        gettrust()
        getaboutdetail()

        getSchemePlanDetails()



    }, [])

    useEffect(() => {
        AOS.init({
            duration: 1500
        });
    }, []);


    var img = [

        setting_datas[0]?.saving_plan_image,
        setting_datas[0]?.digital_plan_image,
        setting_datas[0]?.gold_plant_image

    ];

    { var bgimg = (scheme_slug == 'saving_plan') ? img[0] : (scheme_slug == 'digital_plan' ? img[1] : img[2]) }








    return (
        <>
            <section>
                <div className='head_bn'>
                    <img src={bgimg} />
                    <div className='sdd position-absolute top-0' style={{ width: '100%', height: '100%' }}>
                    </div>
                </div>
            </section>

            <div className='container mb-5'>
                {
                    Isloading ?
                        <div className="tableload mb-tableload text-center">
                            <div className="spinner-border text-primary" role="status"></div>
                        </div>
                        :
                        <>
                            <div className='row justify-content-center'>
                                <div className='col-xl-10 col-lg-12'>

                                    {scheme_slug === 'saving_plan' ? (
                                        <div className='shecme sceeme_shadow'>
                                            <div className='row'>
                                                <div className='col-lg-4 mb-4'>
                                                    <div className='sts_flex'>
                                                        <div className='sc_img'>
                                                            <img src='/assets/images/sav_img.png' alt='' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-8 mb-4'>
                                                    <div className='sc_head'>
                                                        <h1 className=''>{PlanDetails.name}</h1>
                                                    </div>

                                                    <div className="sssssshape_img">
                                                        <img src='/assets/images/sq_img.png' alt='' />
                                                    </div>

                                                    <div className='ex_head'>
                                                        <div className='sav_bg'>
                                                            <img src='/assets/images/sav_bg.png' alt='' />
                                                        </div>
                                                        <div className='ex_keds'>
                                                            <h3 className='text-center'>{PlanDetails.label}</h3>
                                                        </div>
                                                    </div>
                                                    <div className='row'>

                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className='sav_flx'>
                                                                <div className='icon_img'>
                                                                    <img src='/assets/images/shap_icon.png' alt='' />
                                                                </div>
                                                                <p>Punctuality Bonus: <span>{PlanDetails?.punctuality_bonus}%</span></p>
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className='sav_flx'>
                                                                <div className='icon_img'>
                                                                    <img src='/assets/images/shap_icon.png' alt='' />
                                                                </div>
                                                                <p>Begin your plan at: <span>₹{PlanDetails?.min_price}</span></p>
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className='sav_flx'>
                                                                <div className='icon_img'>
                                                                    <img src='/assets/images/shap_icon.png' alt='' />
                                                                </div>
                                                                <p>Profit Rate: <span>{PlanDetails?.bonus}%</span></p>
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className='sav_flx'>
                                                                <div className='icon_img'>
                                                                    <img src='/assets/images/shap_icon.png' alt='' />
                                                                </div>
                                                                <p>Invest up to: <span>₹{PlanDetails?.max_price}</span></p>
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-12'>
                                                            <div className='sav_flx num_p'>
                                                                <div className='icon_img'>
                                                                    <img src='/assets/images/shap_icon.png' alt='' />
                                                                </div>
                                                                <p>Start investing today and receive <span>
                                                                    <div className='grams_num'>
                                                                        <h1>{PlanDetails?.offers}</h1>
                                                                        <div className='gem_nun'>
                                                                            <h4>grams</h4>
                                                                            <h3>{PlanDetails?.offer_type}</h3>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-12'>
                                                            <div className='sce_btn'>
                                                                <button type='button' onClick={() => {
                                                                    if (detail_info && token) {

                                                                        if (PlanDetails.plan_title == 'digital_plan' && PlanDetails?.cancel_status === 0) {
                                                                            toast.error('This Scheme already purchased')

                                                                        } else {
                                                                            setSchemaData(PlanDetails);
                                                                            setprice();
                                                                            document.getElementById('agreecheck').checked = false;
                                                                            document.getElementById("openPriceplanpopu")?.click();
                                                                            setchecked(false)
                                                                        }

                                                                    }
                                                                    else {
                                                                        setSchemaData(PlanDetails);
                                                                        setprice();
                                                                        document.getElementById("loginPopupopen")?.click();
                                                                        document.getElementById('agreecheck').checked = false;
                                                                        setchecked(false)
                                                                        setopenSchemes(true);

                                                                    }
                                                                }} className='btn d-flex align-items-center gap-2'>Buy scheme <img alt='' src='/assets/iocns/Arrow right.png' className='' />
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    ) : null}

                                    {scheme_slug === 'digital_plan' ? (
                                        <div className='shecme sceeme_shadow'>
                                            <div className='row'>
                                                <div className='col-lg-4 mb-4'>
                                                    <div className='sts_flex'>
                                                        <div className='sc_img'>
                                                            <img src='/assets/images/digi_img.png' alt='' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-8 mb-4'>
                                                    <div className='sc_head'>
                                                        <h1 className=''>{PlanDetails.name}</h1>
                                                    </div>

                                                    <div className="sssssshape_img">
                                                        <img src='/assets/images/sq_img.png' alt='' />
                                                    </div>

                                                    <div className='ex_head'>
                                                        <div className='sav_bg'>
                                                            <img src='/assets/images/sav_bg.png' alt='' />
                                                        </div>
                                                        <div className='ex_keds'>
                                                            <h3 className='text-center'>{PlanDetails.label}</h3>
                                                        </div>
                                                    </div>
                                                    <div className='row'>

                                                        <div className='col-lg-6 col-md-6 order-1'>
                                                            <div className='sav_flx'>
                                                                <div className='icon_img'>
                                                                    <img src='/assets/images/shap_icon.png' alt='' />
                                                                </div>
                                                                <p><span>{PlanDetails?.content}</span> to active the plan</p>
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-6 col-md-6 order-2'>
                                                            <div className='sav_flx'>
                                                                <div className='icon_img'>
                                                                    <img src='/assets/images/shap_icon.png' alt='' />
                                                                </div>
                                                                <p>Begin your plan at: <span>₹{PlanDetails?.min_price}</span></p>
                                                            </div>
                                                        </div>






                                                        <div className='col-lg-6 col-md-6 order-md-3 order-4'>
                                                            <div className='sav_flx nd_s'>
                                                                <div className='icon_img'>
                                                                    <img src='/assets/images/shap_icon.png' alt='' />
                                                                </div>
                                                                <p>Start investing today and receive <span>
                                                                    <div className='grams_num'>
                                                                        <h1>{PlanDetails?.offers}</h1>
                                                                        <div className='gem_nun'>
                                                                            <h4>grams</h4>
                                                                            <h3>{PlanDetails?.offer_type}</h3>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-6 col-md-6 order-md-4 order-3'>
                                                            <div className='sav_flx'>
                                                                <div className='icon_img'>
                                                                    <img src='/assets/images/shap_icon.png' alt='' />
                                                                </div>
                                                                <p>Invest up to: <span>₹{PlanDetails?.max_price}</span></p>
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-12 order-5'>
                                                            <div className='sce_btn'>
                                                                <button type='button' onClick={() => {
                                                                    if (detail_info && token) {

                                                                        if (PlanDetails.plan_title == 'digital_plan' && PlanDetails?.cancel_status === 0) {
                                                                            toast.error('This Scheme already purchased')

                                                                        } else {
                                                                            setSchemaData(PlanDetails);
                                                                            setprice();
                                                                            document.getElementById('agreecheck').checked = false;
                                                                            document.getElementById("openPriceplanpopu")?.click();
                                                                            setchecked(false)
                                                                        }

                                                                    }
                                                                    else {
                                                                        setSchemaData(PlanDetails);
                                                                        setprice();
                                                                        document.getElementById("loginPopupopen")?.click();
                                                                        document.getElementById('agreecheck').checked = false;
                                                                        setchecked(false)
                                                                        setopenSchemes(true);

                                                                    }
                                                                }} className='btn d-flex align-items-center gap-2'>Buy scheme <img alt='' src='/assets/iocns/Arrow right.png' className='' />
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    ) : null}


                                    {scheme_slug === 'gold_plant' ? (
                                        <div className='shecme sceeme_shadow'>
                                            <div className='row'>
                                                <div className='col-lg-4 mb-4'>
                                                    <div className='sts_flex'>
                                                        <div className='sc_img'>
                                                            <img src='/assets/images/gp_img.png' alt='' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-8 mb-4'>
                                                    <div className='sc_head'>
                                                        <h1 className=''>{PlanDetails.name}</h1>
                                                    </div>

                                                    <div className="sssssshape_img">
                                                        <img src='/assets/images/sq_img.png' alt='' />
                                                    </div>

                                                    <div className='ex_head'>
                                                        <div className='sav_bg'>
                                                            <img src='/assets/images/sav_bg.png' alt='' />
                                                        </div>
                                                        <div className='ex_keds'>
                                                            <h3 className='text-center'>{PlanDetails.label}</h3>
                                                        </div>
                                                    </div>
                                                    <div className='row'>


                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className='sav_flx'>
                                                                <div className='icon_img'>
                                                                    <img src='/assets/images/shap_icon.png' alt='' />
                                                                </div>
                                                                <p><span>{PlanDetails?.content}</span></p>
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className='sav_flx'>
                                                                <div className='icon_img'>
                                                                    <img src='/assets/images/shap_icon.png' alt='' />
                                                                </div>
                                                                <p>Invest up to: <span>₹{PlanDetails?.max_price}</span></p>
                                                            </div>
                                                        </div>




                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className='sav_flx'>
                                                                <div className='icon_img'>
                                                                    <img src='/assets/images/shap_icon.png' alt='' />
                                                                </div>
                                                                <p>Begin your plan at: <span>₹{PlanDetails?.min_price}</span></p>
                                                            </div>
                                                        </div>








                                                        {/* <div className='col-lg-6 col-md-6'>
                                                            <div className='sav_flx'>
                                                                <div className='icon_img'>
                                                                    <img src='/assets/images/shap_icon.png' alt='' />
                                                                </div>
                                                                <p>Returns Year: <span>{PlanDetails?.returns_year} Years</span></p>
                                                            </div>
                                                        </div> */}

                                                        {/* <div className='col-lg-6 col-md-6'>
                                                            <div className='sav_flx'>
                                                                <div className='icon_img'>
                                                                    <img src='/assets/images/shap_icon.png' alt='' />
                                                                </div>
                                                                <p>Payout Term: <span>{PlanDetails?.payout_term}</span></p>
                                                            </div>
                                                        </div> */}




                                                        <div className='col-lg-12'>
                                                            <div className='sav_flx num_p position-relative sasssssses'>
                                                                <div className='icon_img'>
                                                                    <img src='/assets/images/shap_icon.png' alt='' />
                                                                </div>
                                                                <p>Start investing today and receive <span>
                                                                    <div className='grams_num grams_pos'>
                                                                        <h1>{PlanDetails?.offers}</h1>
                                                                        <div className='gem_nun'>
                                                                            <h4>grams</h4>
                                                                            <h3>{PlanDetails?.offer_type}</h3>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-12'>
                                                            <div className='sce_btn'>
                                                                <button type='button' onClick={() => {
                                                                    if (detail_info && token) {

                                                                        if (PlanDetails.plan_title == 'digital_plan' && PlanDetails?.cancel_status === 0) {
                                                                            toast.error('This Scheme already purchased')

                                                                        } else {
                                                                            setSchemaData(PlanDetails);
                                                                            setprice();
                                                                            document.getElementById('agreecheck').checked = false;
                                                                            document.getElementById("openPriceplanpopu")?.click();
                                                                            setchecked(false)
                                                                        }

                                                                    }
                                                                    else {
                                                                        setSchemaData(PlanDetails);
                                                                        setprice();
                                                                        document.getElementById("loginPopupopen")?.click();
                                                                        document.getElementById('agreecheck').checked = false;
                                                                        setchecked(false)
                                                                        setopenSchemes(true);

                                                                    }
                                                                }} className='btn d-flex align-items-center gap-2'>Buy scheme <img alt='' src='/assets/iocns/Arrow right.png' className='' />
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    ) : null}

                                </div>


                                {/* {scheme_slug === 'saving_plan' ? (
                                    <>
                                        <p>Lock in Period: {PlanDetails?.plan_tenure} months</p>
                                        <p>Bonus: {PlanDetails?.bonus} %</p>
                                        <p>Punctuality Bonus: {PlanDetails?.punctuality_bonus}</p>
                                        <p>Payment Type: {PlanDetails?.pay_type || 'N/A'}</p>
                                    </>
                                ) : null}

                                {scheme_slug === 'gold_plant' ? (
                                    <>
                                        <p>Returns Year: {PlanDetails?.returns_year}</p>
                                        <p>Payment Term: {PlanDetails?.payout_term || 'N/A'}</p>
                                    </>
                                ) : null} */}
                            </div>


                            {/* Razorpay */}

                            <section>
                                <button type="button" className="d-none" data-bs-toggle="modal" id='openPriceplanpopu' data-bs-target="#razarpaypopup"> sssdsdsd</button>

                                <div className="modal fade" id="razarpaypopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="razarpaypopupLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className={scheme_slug === "gold_plant" ? 'modal-content paybox schemecard' : 'modal-content schemecard'}>
                                            <div className="modal-header">
                                                <h1 className="modal-title fs-5 schemename" id="razarpaypopupLabel">{scheme_slug === "digital_plan" ? `${PlanDetails?.name}(Cash Back)` : scheme_slug === "gold_plant" ? `${PlanDetails?.name}(Monthly Rental)` : PlanDetails?.name}</h1>

                                                <button type="button" className="btn-close" id='closepriceModal' data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('Rupees').value = null; setprice(null) }} ></button>
                                            </div>
                                            <div className="modal-body">
                                                <form ref={fromsubmit} onSubmit={(e) => {
                                                    e.preventDefault();

                                                    if (!price) {
                                                        toast.error("Please Enter Price!")
                                                    } else if (scheme_slug === "gold_plant") {
                                                        const amount = parseFloat(price) + parseFloat(((price * 6 / 100) + (price * 3 / 100)))

                                                        if (parseInt(price) < parseInt(PlanDetails?.min_price)) {
                                                            toast.error(`Minmum Amount is ${PlanDetails?.min_price}!`)
                                                        } else if (parseInt(price) > PlanDetails?.max_price) {
                                                            toast.error(`Maximum Amount is ${PlanDetails?.max_price}!`)
                                                        } else if (checked !== true) {
                                                            toast.error('Please Agree Terms & conditions')
                                                        }
                                                        else {
                                                            document.getElementById('closepriceModal')?.click();
                                                            document.getElementById('payment-pup').click()
                                                            document.getElementById('inlineRadio1').checked = false;
                                                            document.getElementById('inlineRadio2').checked = false;
                                                            document.getElementById('inlineRadio3').checked = false;
                                                            document.getElementById('Rupees').value = null
                                                            setprocess_type();
                                                            setclientamount(price)
                                                            setChargeAmt(amount)
                                                            setBenifitid(benificid)

                                                        }
                                                    } else {
                                                        if (parseInt(price) < parseInt(PlanDetails?.min_price)) {
                                                            toast.error(`Minmum Amount is ${PlanDetails?.min_price}!`)
                                                        } else if (parseInt(price) > PlanDetails?.max_price) {
                                                            toast.error(`Maximum Amount is ${PlanDetails?.max_price}!`)
                                                        } else if (checked !== true) {
                                                            toast.error('Please Agree Terms & conditions')
                                                        }
                                                        else {
                                                            document.getElementById('closepriceModal')?.click();
                                                            document.getElementById('payment-pup').click()
                                                            document.getElementById('inlineRadio1').checked = false;
                                                            document.getElementById('inlineRadio2').checked = false;
                                                            document.getElementById('inlineRadio3').checked = false;
                                                            setprocess_type();
                                                            setclientamount(price)

                                                        }
                                                    }

                                                }}>

                                                    {
                                                        scheme_slug === "digital_plan" ? '' : (
                                                            <div className="mb-3">
                                                                <label className="col-form-label fw-bold">Beneficiaries</label>
                                                                <select className="form-select p-3" onChange={(e) => { setbenificid(e.target.value) }} aria-label="ben" >
                                                                    <option value={''} selected>{detail_info?.name}</option>
                                                                    {
                                                                        benfic.map((data, i) => (
                                                                            <option value={data?.id} key={i}>{data.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        )
                                                    }
                                                    <div className="mb-3">
                                                        <label htmlFor="Rupees" className="form-label"><span className='fw-bold'>Price</span> <small className='text-danger'>{`(Minimum Amount ${PlanDetails?.min_price} to Maximum Amount 500000)`}</small></label>
                                                        <input type="text" maxLength={10} value={price} onChange={(e) => setprice(e.target.value?.replace(/[^0-9]/g, ""))} className="form-control p-3" id="Rupees" placeholder={`Ex: ${PlanDetails?.min_price}`} />
                                                        {/* {scheme_slug === "gold_plant" && price ? <span className='text-color'>Deduct Gst & Service tax  : {(price - ((price * 6 / 100) + (price * 3 / 100))).toFixed(2)}</span> : ''} */}
                                                        {scheme_slug === "gold_plant" ? (<><p className='text-color m-0'>VAT charge : {price ? (price * 3 / 100).toFixed(2) : 0}</p>
                                                            <p className='text-color m-0'>Service charge : {price ? (price * 6 / 100).toFixed(2) : 0}</p>
                                                            <p className='text-color m-0'>VAT + Service  : {price ? ((price * 6 / 100) + (price * 3 / 100)).toFixed(2) : 0}</p>
                                                            <p className='text-color m-0'>Total Amount   : {price ? (parseFloat(price) + ((price * 6 / 100) + (price * 3 / 100))) : 0}</p>
                                                        </>) : ''}
                                                    </div>
                                                    <div className="mb-3">
                                                        <input className="form-check-input" type="checkbox" value="" id="agreecheck" onChange={(e) => { setchecked(e.target.checked) }} />

                                                        <label className="form-check-label ms-2" htmlFor="flexCheckDefault">

                                                            I agree <span className='termsborder'>Terms and Condition</span>
                                                        </label>
                                                    </div>


                                                    <div className="mb-3">
                                                        <div className="update-profile  mx-2">
                                                            <button className="text-white" type="submit">Continue</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </section>





                            {/* Rayzor pay End */}


                        </>
                }
            </div>



            <div className='cit_bg'>
                <div className='container'>
                    <div className='shecme-table'>
                        {scheme_slug === 'saving_plan' && (
                            <>
                                <div>
                                    <div className='d-flex justify-content-center w-100 text-center titsksd mb-3'>
                                        <div>
                                            <div data-aos="fade-up-right">
                                                <img src='/assets/images/shpas.png' alt='' />
                                            </div>
                                            <div data-aos="fade-up-left">
                                                <h2 className='fw-bold mb-0'>Gold Chit Savings Scheme</h2>
                                                <h2 className='fw-bold mb-4'>(*Approx Table)</h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mb-3'>
                                        <div data-aos="fade-up">
                                            <div className='d-flex schematable gap-2'>

                                                <div className='cit_table'>
                                                    <div className='mb-2 leaft leaft-head text-center m1'>
                                                        <h5 className='text-light'>Month</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center  m2'>
                                                        <h5>January</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center m3'>
                                                        <h5>February</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center m4'>
                                                        <h5>March</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center m5'>
                                                        <h5>April</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center m6'>
                                                        <h5>May</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center m7'>
                                                        <h5>June</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m8'>
                                                        <h5>July</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m9'>
                                                        <h5>August</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m4'>
                                                        <h5>September</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m11'>
                                                        <h5>October</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m12'>
                                                        <h5>November</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m13'>
                                                        <h5>December</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-cente'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center d1'>
                                                        <h5>December</h5>
                                                    </div>
                                                </div>

                                                <div className='cit_table'>
                                                    <div className='mb-2 leaft leaft-head text-center G1'>
                                                        <h5 className='text-light'>Date</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center  G2'>
                                                        <h5>02-01-2025</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center G3'>
                                                        <h5>03-02-2025</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center m4'>
                                                        <h5>05-03-2025</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center G5'>
                                                        <h5>02-04-2025</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center G6'>
                                                        <h5>04-05-2025</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center G7'>
                                                        <h5>03-06-2025</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center G7'>
                                                        <h5>04-07-2025</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center G7'>
                                                        <h5>03-08-2025</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m4'>
                                                        <h5>05-09-2025</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center G7'>
                                                        <h5>02-10-2025</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center G7'>
                                                        <h5>04-11-2025</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center G7'>
                                                        <h5>01-12-2025</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-cente'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center d1'>
                                                        <h5>01-12-2025</h5>
                                                    </div>
                                                </div>

                                                <div className='cit_table'>
                                                    <div className='mb-2 leaft  leaft-head  text-center C1'>
                                                        <h5>Deposited  Amount </h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2 '>
                                                        <h5>₹ 1000 x 1m</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹1000 x 2m</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center m4'>
                                                        <h5>3rd Bonus month</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1000 x 4m</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1000 x 5m</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹1000 x 6m</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1000 x 7m</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1000 x 8m</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m4'>
                                                        <h5>9th Bonus month</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1000 x 10m</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1000 x 11m</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1000 x 12m</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center m4'>
                                                        <h5>Total Sum</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center d1'>
                                                        <h5>5% Punchuality Bonus (₹ 500 *Approx)</h5>
                                                    </div>
                                                </div>

                                                <div className='cit_table'>
                                                    <div className='mb-2 leaft  leaft-head text-center C1'>
                                                        <h5>Gold Rate As Per Payment Date (*Approx)</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 8000</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 8,265</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center m4'>
                                                        <h5>₹ 8,302</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 8,341</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 8,382</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 8,420</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 8,486</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 8,501</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m4'>
                                                        <h5>₹ 8,545</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 8,583</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 8,612</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 8,675</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-cente'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center d1'>
                                                        <h5>₹ 8,675</h5>
                                                    </div>

                                                </div>

                                                <div className='cit_table'>
                                                    <div className='mb-2 leaft  leaft-head text-center C1'>
                                                        <h5>Gold Accumulated Monthly (grams) (*Approx)</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>0.12181</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>0.12099</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center m4'>
                                                        <h5>0.12045</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>0.11988</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>0.1193</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>0.11876</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>0.11784</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>0.11763</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m4'>
                                                        <h5>0.11702</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>0.1165</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>0.11611</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>0.11527</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-cente'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center d1'>
                                                        <h5>0.05763</h5>
                                                    </div>
                                                </div>

                                                <div className='cit_table'>
                                                    <div className='mb-2 leaft text-center px-lg-1 leaft-head F1'>
                                                        <h5 className='text-light m-0'>Total Gold Accumulated (grams) (*Approx)</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center F2'>
                                                        <h5>0.12181</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center F3'>
                                                        <h5>0.2428</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center m4'>
                                                        <h5>0.36325</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center F5'>
                                                        <h5>0.48313</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center F6'>
                                                        <h5>0.60243</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center F7'>
                                                        <h5>0.07368</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center F7'>
                                                        <h5>0.83903</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center F7'>
                                                        <h5>0.95666</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m4'>
                                                        <h5>1.07368</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center F7'>
                                                        <h5>1.19018</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center F7'>
                                                        <h5>1.30629</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center F7'>
                                                        <h5>1.42156</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center m4'>
                                                        <h5>1.42156</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center d1'>
                                                        <h5>1.47919</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>
                        )}
                        {scheme_slug === 'digital_plan' && (
                            <>
                                <div className='d-flex justify-content-center w-100 text-center titsksd mb-3'>
                                    <div>
                                        <div data-aos="fade-up-right">
                                            <img src='/assets/images/shpas.png' alt='' />
                                        </div>
                                        <div data-aos="fade-up-left">
                                            <h2 className='fw-bold mb-0'>Returns with Gold Investment Leaf </h2>
                                            <h2 className='fw-bold mb-4'>(SIP Scheme)</h2>
                                        </div>
                                    </div>
                                </div>

                                <div className='mb-3'>
                                    <div data-aos="fade-up">
                                        <div className='d-flex schematable gap-2'>
                                            <div>
                                                <div className='mb-2 leaft leaft-head text-center G1'>
                                                    <h5 className='text-light'>Gold Investment Leaf Scheme</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center  G2'>
                                                    <h5>3 months (0-90 days)</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center G3'>
                                                    <h5>6 months (90-180 days)</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center G4'>
                                                    <h5>9 months (180 - 270 days)</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center G5'>
                                                    <h5>12 months (270 - 360 days)</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center G6'>
                                                    <h5>2nd Year</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center G7'>
                                                    <h5>3rd Year</h5>
                                                </div>
                                            </div>

                                            <div>
                                                <div className='mb-2 leaft  leaft-head  text-center C1'>
                                                    <h5>GreenHeap Gold approx. (1,000 assured)</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center C2 '>
                                                    <h5>(0.25% x 3m) Rs. 7.50</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center C2'>
                                                    <h5>(0.5% X 6m) Rs. 30.00</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center C2'>
                                                    <h5>(0.75% X 9m) Rs. 67.50</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center C2'>
                                                    <h5>(1% X 12m) Rs. 120.00</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center C2'>
                                                    <h5>(1% X 24m) Rs. 240.00</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center C2'>
                                                    <h5>(1% X 36m) Rs. 360.00</h5>
                                                </div>
                                            </div>

                                            <div>
                                                <div className='mb-2 leaft  leaft-head text-center C1'>
                                                    <h5>GreenHeap Silver Bonus</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center C2'>
                                                    <h5>(9.6% per annum For 3m) Rs. 24.00</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center C2'>
                                                    <h5>(10% per annum For 6m) Rs. 50.00</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center C2'>
                                                    <h5>(12% per annum For 9m) Rs. 90.00</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center C2'>
                                                    <h5>(14% per annum For 12m) Rs. 140.00</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center C2'>
                                                    <h5>(18% per annum For 24m) Rs. 360.00</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center C2'>
                                                    <h5>(20% per annum For 36m) Rs. 600.00</h5>
                                                </div>
                                            </div>

                                            <div>
                                                <div className='mb-2 leaft  leaft-head text-center C1'>
                                                    <h5>GreenHeap Gold + Silver Bonus</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center C2'>
                                                    <h5>(7.50 + 24.00) Rs. 31.5</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center C2'>
                                                    <h5>(30.00 + 50.00) Rs. 80</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center C2'>
                                                    <h5>(67.50 + 90.00) Rs. 157.5</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center C2'>
                                                    <h5>(120.00 + 140.00) Rs. 260</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center C2'>
                                                    <h5>(240.00 + 360.00) Rs. 600</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center C2'>
                                                    <h5>(360.00 + 600.00) Rs. 960</h5>
                                                </div>
                                            </div>

                                            <div>
                                                <div className='mb-2 leaft text-center px-lg-1 leaft-head F1'>
                                                    <h5 className='text-light m-0'>Sip Total Returns</h5>
                                                    <h5 className='text-light'>(Cash Back)</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center F2'>
                                                    <h5>31.5</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center F3'>
                                                    <h5>80</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center F4'>
                                                    <h5>157.5</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center F5'>
                                                    <h5>260</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center F6'>
                                                    <h5>600</h5>
                                                </div>

                                                <div className='mb-2 leaft text-center F7'>
                                                    <h5>960</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>
                        )}
                        {scheme_slug === 'gold_plant' && (
                            <>
                                <div>
                                    <div className='d-flex justify-content-center w-100 text-center titsksd mb-3'>
                                        <div>
                                            <div data-aos="fade-up-right">
                                                <img src='/assets/images/shpas.png' alt='' />
                                            </div>
                                            <div data-aos="fade-up-left">
                                                <h2 className='fw-bold mb-0'>Greenheap Gold Plant Scheme</h2>
                                                <h2 className='fw-bold mb-4'>(*Approx Table)</h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mb-3'>
                                        <div data-aos="fade-up">
                                            <div className='d-flex schematable gap-2'>

                                                <div className='cit_table'>
                                                    <div className='mb-2 leaft leaft-head text-center m1'>
                                                        <h5 className='text-light'>Years</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center  m2'>
                                                        <h5>0 Year</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center m3'>
                                                        <h5>1st Year</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center mmm'>
                                                        <h5>2st Year</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center m5'>
                                                        <h5>3st Year</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center m6'>
                                                        <h5>4st Year</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center m7'>
                                                        <h5>5st Year</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m8'>
                                                        <h5>6st Year</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m9'>
                                                        <h5>7st Year</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m11'>
                                                        <h5>8st Year</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m11'>
                                                        <h5>9st Year</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m12'>
                                                        <h5>10st Year</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m13'>
                                                        <h5>11st Year</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center m13'>
                                                        <h5>12st Year</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m13'>
                                                        <h5>13st Year</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m13'>
                                                        <h5>14st Year</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m13'>
                                                        <h5>15st Year</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m13'>
                                                        <h5>16st Year</h5>
                                                    </div>
                                                </div>

                                                <div className='cit_table'>
                                                    <div className='mb-2 leaft leaft-head text-center G1'>
                                                        <h5 className='text-light'>Joining Date</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center G7'>
                                                        <h5>05-01-2025</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                </div>

                                                <div className='cit_table'>
                                                    <div className='mb-2 leaft  leaft-head  text-center C1'>
                                                        <h5>Deposited  Amount </h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2 app_h'>
                                                        <h5>₹ 1,50,000 *Gst & service charge applicable</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>


                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2 lst_c'>

                                                    </div>
                                                </div>

                                                <div className='cit_table'>
                                                    <div className='mb-2 leaft  leaft-head text-center C1'>
                                                        <h5>Monthly Gold Returns Through Bank (*Approx)</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center m4'>
                                                        <h5>Early Bird Offer within 3days after KYC</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1500x12=18,000</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1500x12=18,000</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1500x12=18,000</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1500x12=18,000</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1500x12=18,000</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1500x12=18,000</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1500x12=18,000</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1500x12=18,000</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1500x12=18,000</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1500x12=18,000</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1500x12=18,000</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1500x12=18,000</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1500x12=18,000</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1500x12=18,000</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1500x12=18,000</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1500x12=18,000</h5>
                                                    </div>

                                                </div>

                                                <div className='cit_table'>
                                                    <div className='mb-2 leaft  leaft-head text-center C1'>
                                                        <h5>Surrender Scheme</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 56,000</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 98761</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 1,80,942</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center C2'>
                                                        <h5>₹ 4,04,192</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m4'>
                                                        <h5>₹ 4,70,000</h5>
                                                    </div>
                                                </div>

                                                <div className='cit_table'>
                                                    <div className='mb-2 leaft text-center px-lg-1 leaft-head F1'>
                                                        <h5 className='text-light m-0'></h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center F2'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center F3'>
                                                        <h5>* 1st year surrender scheme</h5>
                                                    </div>

                                                    <div className='mb-2 leaft text-center F4'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center F5'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center F6'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center F7'>
                                                        <h5>* 5th year surrender scheme</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center F7'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center F7'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center F7'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center F7'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center F7'>
                                                        <h5>* 10th year surrender scheme</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center F7'>

                                                    </div>

                                                    <div className='mb-2 leaft text-center F7'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center F7'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center F7'>

                                                    </div>
                                                    <div className='mb-2 leaft text-center F7'>
                                                        <h5>* 15th year surrender scheme</h5>
                                                    </div>
                                                    <div className='mb-2 leaft text-center m4'>
                                                        <h5>* Capital Tripled scheme disclosure</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <section className="my-5 py-2">
                <div className="container py-2 termpagesize">
                    <div style={{
                        color: "red"
                    }} className='shecme-links'>
                        <div data-aos="fade-left" class="aos-init aos-animate">
                            <div class="">
                                <h5 className="terms-text">
                                    <span class="">
                                        * Terms & Conditions are apply
                                    </span>
                                </h5>
                            </div>
                        </div>
                    </div>
                    {
                        Isloading ? Array.from({ length: 3 }).map((_, i) => (
                            <div className="my-3 " key={i}>
                                <div><Shimmer width={400} height={40} className="rounded" /></div>
                                <div><Shimmer height={150} className="rounded w-100 mt-2" /></div>
                            </div>
                        ))
                            : PlanDetails ? (<h4 className='1bh-base fw-normal ' dangerouslySetInnerHTML={{ __html: PlanDetails?.terms }}></h4>) : ''
                    }
                </div>

                <div className='container'>
                    <div className='cance_policy'>
                        <a href="/pages/refund-cancellation-policy">* Refund & Cancellation Policy</a>
                    </div>
                </div>



            </section >

            {/* <section>
                <div className='container'>
                    <a href="/refund-cancellation-policy">* Refund & Cancellation Policy</a>
                </div>
            </section> */}



            {/* qr-code-modal */}

            <section>
                <button type="button" className="btn btn-primary d-none" data-toggle="modal" data-target="#offlinebox" id='offlinepop-pup'>
                    Launch demo modal
                </button>
                <div className="modal fade" id="offlinebox" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className='modal-content'>
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 schemename" id="razarpaypopupLabel"><span className='mx-2' style={{ cursor: 'pointer' }} onClick={() => { document.getElementById('offlinepop-pup').click(); document.getElementById('payment-pup').click() }}><ArrowLeft /></span>{shemData?.plan_title === "digital_plan" ? `${shemData?.name}(Cash Back)` : shemData?.plan_title === "gold_plant" ? `${shemData?.name}(Monthly Rental)` : shemData?.name}</h1>

                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('offlinepop-pup').click() }} ></button>
                            </div>
                            <div className="modal-body">
                                <div className="qr-detail text-center">
                                    <h6>{setting_datas ? setting_datas[0]?.account_holder : ''}</h6>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <div className="qr-upiid text-center">
                                        <h6 className='mb-0'>Vyapar.171813426519@hdfcbank</h6>
                                    </div>
                                </div>
                                <div className="qq-sacn-img p-2">
                                    <img src={setting_datas ? setting_datas[0]?.qr_code : ''} alt='' className='img-fluid' />
                                </div>
                                <form onSubmit={upimethod}>
                                    <div className="uplo-img ">
                                        <input type='file' className='d-none' id='scanimg' onChange={screenshot} />
                                        <button className='btn' type='button' onClick={() => { document.getElementById('scanimg').click() }}>Upload Transaction Slip <img src='\assets\iocns\upload.png' alt='' className='img-fluid' /></button>

                                        {
                                            file1 ? (
                                                <>
                                                    <div className='uploadfile-name position-relative'>
                                                        <p className='m-0'>{file1 ? `File Name : ${file1}` : ''}</p>
                                                        <h6 className='position-absolute remove-upload' onClick={() => { setfile1(); settransfile() }}><X /></h6>
                                                    </div>
                                                </>
                                            ) : ''
                                        }

                                        <input type="text" class="form-control bg-white text-dark border text-center my-2" placeholder="Transaction ID" aria-label="transaction-id" id='transaction-id' onChange={(e) => { setTranscationid(e.target.value) }} />
                                        <p className='text-danger '>* Upload the transaction slip or enter the transaction ID</p>
                                    </div>
                                    <div className="update-profile">
                                        <button className="text-white" type="submit">{spinner ? <span className="spinner-border spinner-border-sm " role="status"></span> : 'Continue'}</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* payment-modal */}

            <section>
                <button type="button" className="btn btn-primary d-none" data-toggle="modal" data-target="#paymentbox" id='payment-pup'>
                    Launch demo modal
                </button>
                <div className="modal fade" id="paymentbox" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className='modal-content'>
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 schemename" id="razarpaypopupLabel">{shemData?.plan_title === "digital_plan" ? `${shemData?.name}(Cash Back)` : shemData?.plan_title === "gold_plant" ? `${shemData?.name}(Monthly Rental)` : shemData?.name}</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('payment-pup').click() }} ></button>
                            </div>
                            <div className="modal-body">
                                <div className="pm-ti">
                                    <h5> Choose Payment Method</h5>
                                </div>
                                <div className="pm-choice ">
                                    <form onSubmit={paymentfun}>
                                        <div className={process_type == '1' ? 'form-check act' : 'form-check'}>
                                            <div className="selecttypes p-2 d-flex align-items-center gap-2">
                                                <input class="form-check-input mb-1" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="1" onChange={(e) => { setprocess_type(e.target.value) }} />
                                                {/* <label class="form-check-label" for="inlineRadio1">Online Razarpay</label> */}
                                                <div className='razoricon'>
                                                    <img src='\assets\images\visa.png' className='img-fluid' alt='' />
                                                    <img src='\assets\images\mascard.png' className='img-fluid' alt='' />
                                                    <img src='\assets\images\rupay.png' className='img-fluid' alt='' />
                                                    <img src='\assets\images\phopay.png' className='img-fluid' alt='' />
                                                    <img src='\assets\images\mastro.png' className='img-fluid' alt='' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={process_type == '2' ? 'form-check act' : 'form-check'} >
                                            <div className="selecttypes p-2  d-flex align-items-center gap-2">
                                                <input class="form-check-input  mb-1" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="2" onChange={(e) => { setprocess_type(e.target.value) }} />
                                                {/* <label class="form-check-label" for="inlineRadio2">QR code / UPI</label> */}
                                                <label class="form-check-label" for="inlineRadio2">Direct UPI Payments (QR Code) </label>
                                                <div className='razoricon'><img src='\assets\images\upis.png' className='img-fluid' alt='' /></div>
                                            </div>
                                        </div>
                                        <div className={process_type == '3' ? 'form-check act' : 'form-check'}>
                                            <div className="selecttypes py-3 px-2  d-flex align-items-center gap-2">
                                                <input class="form-check-input  mb-1" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="3" onChange={(e) => { setprocess_type(e.target.value) }} />
                                                {/* <label class="form-check-label" for="inlineRadio3">Bank Details</label> */}
                                                <label class="form-check-label" for="inlineRadio3">For RTGS / NEFT / IMPS</label>
                                                <div className='banic'>
                                                    <img src='\assets\images\net_img.png' className='img-fluid' alt='' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="update-profile ">
                                            <button className="text-white" type="submit">Continue</button>
                                        </div>
                                    </form>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* bandetail-modal */}

            <section>
                <button type="button" className="btn btn-primary d-none" data-toggle="modal" data-target="#bankbox" id='bankpop-pup'>
                    Launch demo modal
                </button>
                <div className="modal fade" id="bankbox" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className='modal-content'>
                            <div className="modal-header">
                                <h1 className="modal-title fs-5 schemename" id="razarpaypopupLabel"><span className='mx-1' style={{ cursor: 'pointer' }} onClick={() => { document.getElementById('bankpop-pup').click(); document.getElementById('payment-pup').click() }}><ArrowLeft /></span> {shemData?.plan_title === "digital_plan" ? `${shemData?.name}(Cash Back)` : shemData?.plan_title === "gold_plant" ? `${shemData?.name}(Monthly Rental)` : shemData?.name} </h1>

                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('bankpop-pup').click() }} ></button>
                            </div>
                            <div className="modal-body ac-holdbank">
                                <h5 className='mb-4'>Bank Details</h5>

                                <div className="acc-holder">
                                    <img src='assets\iocns\Profile.png' className='img-fluid' alt='' /><span>{setting_datas ? setting_datas[0]?.account_holder : ''}</span>
                                </div>
                                <div className="acc-holder">
                                    <img src='assets\iocns\accountnumber.png' className='img-fluid' alt='' /><span>{setting_datas ? setting_datas[0]?.account_number : ''}</span>
                                </div>
                                <div className="acc-holder">
                                    <img src='assets\iocns\bank.png' className='img-fluid' alt='' /><span>{setting_datas ? setting_datas[0]?.bank_name : ''}</span>
                                </div>
                                <div className="acc-holder">
                                    <img src='assets\iocns\branchlocatin.png' className='img-fluid' alt='' /><span>{setting_datas ? setting_datas[0]?.branch_name : ''}</span>
                                </div>
                                <div className="acc-holder">
                                    <img src='\assets\iocns\ifsc code.png' className='img-fluid' alt='' /><span>{setting_datas ? setting_datas[0]?.ifsc_code : ''}</span>
                                </div>
                                <form onSubmit={Bankmethod}>

                                    <div className="uplo-img text-start">
                                        <input type='file' className='d-none' id='scanimg' onChange={screenshot} />
                                        <button className='btn ' type='button' onClick={() => { document.getElementById('scanimg').click() }}>Upload Transaction Slip <img src='\assets\iocns\upload.png' alt='' className='img-fluid' /></button>
                                        {
                                            file1 ? (
                                                <>
                                                    <div className='uploadfile-name position-relative'>
                                                        <p className='m-0'>{file1 ? `File Name : ${file1}` : ''}</p>
                                                        <h6 className='position-absolute remove-upload' onClick={() => { setfile1(); settransfile() }}><X /></h6>
                                                    </div>
                                                </>
                                            ) : ''
                                        }
                                        <input type="text" class="form-control bg-white text-dark border text-center my-2" placeholder="Transaction ID" id='transaction-id2' aria-label="transaction-id" onChange={(e) => { setTranscationid(e.target.value) }} />
                                        <p className='text-danger '>* Upload the transaction slip or enter the transaction ID</p>
                                    </div>

                                    <div className="update-profile mt-2 mb-0">
                                        <button className="text-white" type="submit" >{spinner ? <span className="spinner-border spinner-border-sm " role="status"></span> : 'Continue'}</button>
                                    </div>
                                </form>



                            </div>

                        </div>
                    </div>
                </div>
            </section>




            <FAQ scheme_slug={scheme_slug} setting_data={setting_datas} />


        </>
    )
}
